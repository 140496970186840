/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import { Helmet } from "react-helmet"
import PropTypes from "prop-types"

import Header from "./header/header"

const Layout = props => {
  const { title, className, children } = props
  const description =
    "Energía solar accesible para todos. Nuestra meta es que nuestros clientes ahorren en su gasto energético usando tecnologías limpias y confiables."
  const liga = "http://wp.ikaro.mx/wp-content/uploads/2022/05/Liga.jpg"

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Ikaro {title && title !== "" && `- ${title}`}</title>
        <link rel="canonical" href="https://www.ikaro.mx/" />
        <meta property="og:site_name" content="Ikaro" />
        <meta name="description" content={description} />
        <meta name="image" content={liga} />
        <meta property="og:url" content={`https://www.ikaro.mx`} />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content={`Ikaro ${title && title !== "" && `- ${title}`}`}
        />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={liga} />
        <meta property="og:image:secure_url" content={liga} />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:description" content={description} />
        <meta name="twitter:card" content={liga} />
        <meta
          name="twitter:title"
          content={`Ikaro ${title && title !== "" && `- ${title}`}`}
        />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={liga} />
      </Helmet>
      <Header siteTitle={"Ikaro"} />
      <div className={`text-white ${className}`}>
        <main>{children}</main>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
